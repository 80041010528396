import React from 'react'
import Mainimg from './Mainimg'
import About from './About'

export default function Home() {
    return (
        <div>
            <Mainimg />
            <About />
        </div>
    )
}
