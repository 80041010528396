import React from 'react'
import Menuimg from '../assets/images/menu.jpg';
import Menuimg2 from '../assets/images/menu-2.jpg';
import Menupdf from '../assets/images/menu.pdf';
import { Box, Container, Grid } from '@material-ui/core';

export default function Menu() {
    return (
        <div>                 
            <Container maxWidth="lg" component="main">            
            <Grid container spacing={2} alignItems="center" justifyContent="center" >    
            <h2 style={{textAlign:'center', marginTop:'60px'}}> <a href={Menupdf} style={{ color: 'black',   fontSize: '30px'}} target="_blank" rel="noreferrer">View PDF Menu</a></h2>     
            <Grid item xs={12} style={{textAlign:'center'}}> 
            <img src={Menuimg} alt="menu" style={{width:'100%', height:'auto'}}/>
            </Grid>
            <Grid item xs={12}  style={{textAlign:'center'}}> 
            <img src={Menuimg2} alt="menu" style={{width:'100%', height:'auto'}}/>
            </Grid>
            </Grid>
          
            </Container>
        </div>
    )
}
