import React from 'react'

export default function Termsofuse() {
    return (
        <div>
             <h1 style={{textAlign:'center'}}>   Terms of Use</h1> 
         
        </div>
    )
}

