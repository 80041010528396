import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Image1 from './../assets/review1.png';
import Image2 from './../assets/review2.png';
import Image3 from './../assets/review3.png';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    minHeight:'76vh',
    overflowX:'hidden',
    
  },
  menu: {
      width:'50%',
      height:'auto',
      textAlign: "center",
      padding:'20px',
      [theme.breakpoints.down('sm')]: {
        width:'90%',
    },
  },
  menuwrap:{
    justifyContent:"center",
    alignContent:"center",
    textAlign:"center"
  }
}));
export default function Reviews() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
     <h1 style={{textAlign:'center',fontFamily: 'Great Vibes',  fontSize: '40px'}}>Google Reviews</h1>
      <Grid container spacing={2} style={{overflowX:'hidden'}}>
        <Grid item  xs={12}  className={classes.menuwrap}  >
         <img src={Image1} className={classes.menu} alt=" menu1" textAlign="center" />
        </Grid>
        <Grid item   xs={12} className={classes.menuwrap}  >
          <img src={Image2} className={classes.menu} alt=" menu2"/>
        </Grid>
        <Grid item   xs={12} className={classes.menuwrap}  >
         <img src={Image3} className={classes.menu} alt=" menu3" textAlign="center"/>
        </Grid>
      </Grid>
      <h2 style={{textAlign:'center'}}>To view our latest Reviews on Google, please <a href="https://www.google.com/search?q=namastey+patio&source=lmns&bih=1608&biw=982&rlz=1C1CHBF_enUS891US891&hl=en&sa=X&ved=2ahUKEwjLxcaB_In0AhUPFFkFHedjBCsQ_AUoAHoECAEQAA#lrd=0x80857df985608481:0xc1e6ccb528567762,1,,,">Click Here.</a></h2>
     
      <div className="tagembed-container" style={{ width:'100%',height:'100%',overflow: 'auto'}}><div className="tagembed-socialwall" data-wall-id="16610" view-url="https://widget.tagembed.com/16610?view">  </div> </div>
    </div>
  );
}