import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import logo from '../assets/logo.png'
import {Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import HomeIcon from '@material-ui/icons/Home';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import InfoIcon from '@material-ui/icons/Info';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  padding: {
    paddingRight: 30,
    marginTop: "55px",
    cursor: "pointer",
  },
  reslogo: {
    height: "70px",
    margin: "10px",
  },
  logo: {
    height: "140px",
    margin: "10px",
    marginTop: "20px",
    marginLeft: "30px",
  },
  flex: {
    display: "flex",
    backgroundColor: "#fafafa",
  },
  flex1: {},
  flex2: {
    marginLeft: "auto",
    marginTop: "17px",
  },
  toplink: {
    fontSize: "24px",
    textDecoration: "none",
    fontFamily: "Josefin Sans",
    fontWeight: "400",
    color: "#091a2d",
    margin: "auto",
    padding: "10px",
    "&:hover": {
      background: "#edc3a5",
      borderRadius: "10px",
    },
  },
  icon: {
    verticalAlign: "middle",
    fontSize: "25px",
    paddingBottom: "5px",
  },
});

function Test2 () { 
  const classes = useStyles();

  return (
    <React.Fragment>   
     <Box display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block' }}>   
      <Grid className={classes.flex} id="top" >
          <Grid className={classes.flex1}>
          <Link to="/">
             <img src={logo} className={classes.logo} alt=""/></Link>
           </Grid>
           <Grid className={classes.flex2}>
           <Toolbar>
          <Typography component={'span'} className = {classes.padding} color="inherit" > 
          <Link to="/" className={classes.toplink}>Home <HomeIcon className={classes.icon}/> </Link></Typography>
         
          <Typography component={'span'} className = {classes.padding} color="inherit" > 
          <a href="/#about" className={classes.toplink}>About <InfoIcon className={classes.icon}/></a></Typography>

          <Typography component={'span'}  className = {classes.padding} color="inherit" >
           <a href="tel:+15109229014" className={classes.toplink} target="_blank" rel="noreferrer"><LocalPhoneIcon className={classes.icon}/> (510) 922-9014  </a></Typography>

            {/* <Typography component={'span'}  className = {classes.padding} color="inherit" >
           <a href="/205113" className={classes.toplink}>Team <GroupIcon className={classes.icon}/> </a></Typography>  */}
      </Toolbar>
      </Grid>        
      </Grid>        
      </Box>  
      {/* mobile  */}
      <Box	display={{ xs: 'block', sm: 'none' }} > 
      <Grid align="center" style={{marginTop: '55px'}}>               
      </Grid>        
      </Box>      
    </React.Fragment>
  );
};

export default Test2;