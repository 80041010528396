import React from "react";
// import './App.css'
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from './components/Home';
 import Termsofuse from './components/Termsofuse';
 import Privacy from './components/Privacy'
 import Footer from './components/Footer';
import Navbar from './components/Navbar'
 import About from './components/About'
 import Menu from './components/Menu'
 import Box from '@material-ui/core/Box';
 import Drawer from './components/Drawer'
 import Gallary from './components/Gallary';
 import Reviews from './components/Reviews';
  
function App() {
  return (
    <BrowserRouter>
    
      <Navbar />
       <Box display={{ md: 'block', lg: 'none' }} ><Drawer /></Box> 
      <Box display={{ xs: 'none', sm: 'block', md: 'none' }}></Box> 
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route path="/about" component={About} ></Route> 
         <Route path="/menu" component={Menu}></Route>
         <Route path="/gallary" component={Gallary}></Route>
         <Route path="/reviews" component={Reviews}></Route>
        <Route path="/privacypolicy" component={Privacy} ></Route>
        <Route path="/termsofuse" component={Termsofuse}></Route>
      </Switch>
       <Footer />
    </BrowserRouter>
  );
}

export default App;

