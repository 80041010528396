import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../index.css";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";

var btnstyles = {
  buttonmob: {
    fontFamily: "Poppins",
    justifyContent: "center",
    backgroundColor: "#cb5b09",
    boxShadow: "rgb(0 0 0 / 50%) 1px 4px 10px 1px",
    alignItems: "center",
    color: "black",
    border: "none",
    padding: "5px",
    fontSize: "10px",
    position: "fixed",
    bottom: "70px",
    left: " 20px",
    zIndex: "1000",
  },
  buttonmob2: {
    fontFamily: "Poppins",
    justifyContent: "center",
    backgroundColor: "#cb5b09",
    boxShadow: "rgb(0 0 0 / 50%) 1px 4px 10px 1px",
    alignItems: "center",
    color: "black",
    border: "none",
    padding: "5px",
    fontSize: "10px",
    position: "fixed",
    bottom: "15px",
    left: " 20px",
    zIndex: "1000",
  },
  toplink: {
    fontSize: "12px",
    textDecoration: "none",
    fontFamily: "Poppins, sans-serif",
    color: "white",
    margin: "auto",
    padding: "5px",
    transform: "scale(1.1)",
    transition: "transform .2s ease-in-out",
  },
};



class ScrollMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
  }
  
  componentDidMount() {
    document.addEventListener("scroll", () => {
      const img = window.scrollY < 150 ? false : true;
      this.setState({ display: img });
    });
  }

  render() {
    
    return (

      <Grid
        style={{
          position: "relative",
        }}
      >
        {this.state.display ? <Smallimg /> : null}
      </Grid>
    );
  }
}
export default ScrollMenu;
function Smallimg() {
  const trigger = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Grid>     
        <Box display={{ md: "block", lg: "none" }}>
          <Button
            variant="outlined"
            size="large"
            color="primary"
            style={btnstyles.buttonmob}
          >
            <a href="tel:+15109229014" style={btnstyles.toplink}>
              {" "}
              Call Now{" "}
            </a>
            <br />
          </Button>
          <Button
            variant="outlined"
            size="large"
            color="primary"
            style={btnstyles.buttonmob2}
          >
            <a
              href="https://www.clover.com/online-ordering/namastey-patio-oakland"
              style={btnstyles.toplink}
              target="_blank"
              rel="noreferrer"
            >
              ORDER ONLINE
            </a>
            <br />
          </Button>
        </Box>     
    </Grid>
  );
}
