
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {  Link } from "react-router-dom";
import Box from '@material-ui/core/Box';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import RoomIcon from '@material-ui/icons/Room';

import {animateScroll as scroll} from 'react-scroll';

function Copyright() {
  return (
    <Typography component={'span'} variant="body2" style={{color: '#aaadaa',   fontFamily: "Poppins, sans-serif"}}>
      
       {/* <Link to='/privacypolicy' style={{textDecoration: 'none', color: '#aaadaa',  fontFamily: "Poppins, sans-serif"}}>Privacy Policy |</Link>
       <Link to='/termsofuse' style={{textDecoration: 'none', color: '#aaadaa',  fontFamily: "Poppins, sans-serif"}}> Terms of Use | </Link> */}
       <Box display={{ xs: 'block', sm: 'none' }}><br/></Box>
      {'Copyright ©     '}
      
         Namastey Patio.
      
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    fontFamily: "Poppins",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  headerlink: {
    textDecoration: "none",
    color: "white",
    fontSize: "28px",
    fontFamily: "Great Vibes",
    textDecoration: "underline",
    textDecorationColor: "#cb5b09",
  },
  link: {
    textDecoration: "none",
    color: "#e4e4e4",
    fontSize: "18px",
    fontFamily: "Poppins, sans-serif",
    lineHeight: "1.5",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  textfield: {
    margin: "5px",
    backgroundColor: "white",
    color: "#213C54",
    borderRadius: "20px",
    paddingLeft: "8px",
  },
  signup: {
    color: "white",
    border: "2px solid white",
    padding: "4px",
    margin: "3px",
  },
}));

  const handleClick = (event) => {
    scroll.scrollToTop();
  };

 
export default function Footer() {
  const classes = useStyles(); 
  return (
    <Grid style={{ backgroundColor: "#165940" }}>
      <Container maxWidth="lg" component="footer" className={classes.footer}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3} md={4} lg={4}>
            <Typography component={"span"} className={classes.headerlink}>
              Namastey Patio
            </Typography>
            <ul>
              <Link to="/" className={classes.link} onClick={handleClick}>
                <li>Home </li>
              </Link>
              <a href="/#about" className={classes.link}>
                <li>About </li>{" "}
              </a>
              <a href="tel:+15109229014" className={classes.link}>
                Call (510) 922-9014{" "}
              </a>
              {/* <a href='/careers' className={classes.link}><li>Careers  </li> </a>           */}
            </ul>
          </Grid>
          <Grid item xs={6} sm={3} md={4} lg={4}>
            <Typography
              component={"span"}
              gutterBottom
              className={classes.headerlink}
            >
              Quick Links
            </Typography>
            <ul>
              <Link to="/menu" className={classes.link}>
                <li>Menu </li>
              </Link>
              <Link to="/gallary" className={classes.link}>
                <li>Gallary </li>
              </Link>
              <Link to="/reviews" className={classes.link}>
                <li>Reviews </li>
              </Link>
              <a
                href="https://www.clover.com/online-ordering/namastey-patio-oakland"
                className={classes.link}
              >
                <li>Order Online </li>
              </a>
            </ul>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} className={classes.getint}>
            <Typography
              component={"span"}
              className={classes.link}
              style={{ padding: "5px", fontFamily: "Poppins, sans-serif" }}
            >
              5500 College Ave Oakland, CA 94618{" "}
            </Typography>

            <Typography
              component="h2"
              className={classes.headerlink}
              style={{
                paddingLeft: "5px",
                paddingBottom: "5px",
                fontSize: "22px",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {" "}
              Get in Touch with us!
            </Typography>
            <Typography
              component="h2"
              className={classes.headerlink}
              style={{
                paddingLeft: "5px",
                paddingBottom: "5px",
                fontSize: "18px",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {" "}
              <a href="mailto:info@namasteypatio.com" style={{textDecoration:'none', color:'lightgrey'}}>Email: info@namasteypatio.com</a>
            </Typography>
            <Typography
              component={"span"}
              className={classes.link}
              style={{ padding: "5px" }}
            >
              <a
                href="https://www.facebook.com/Namasteypatio/"
                className={classes.link}
              >
                <FacebookIcon style={{ fontSize: "50px" }} />
              </a>
            </Typography>
            <Typography
              component={"span"}
              className={classes.link}
              style={{ padding: "5px" }}
            >
              <a
                href="https://www.instagram.com/namasteypationepalese/"
                className={classes.link}
              >
                <InstagramIcon style={{ fontSize: "50px" }} />
              </a>
            </Typography>
            <a
              href="https://www.google.com/maps/place/Namastey+Patio+Nepalese+and+Indian+Cuisine/@37.8428058,-122.2535305,17z/data=!4m12!1m6!3m5!1s0x80857df985608481:0xc1e6ccb528567762!2sNamastey+Patio+Nepalese+and+Indian+Cuisine!8m2!3d37.8428058!4d-122.2513418!3m4!1s0x80857df985608481:0xc1e6ccb528567762!8m2!3d37.8428058!4d-122.2513418"
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                component={"span"}
                className={classes.link}
                style={{ padding: "5px" , }}
              >
                <RoomIcon style={{ fontSize: "50px" }} />
              </Typography>{" "}
            </a>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
        <Grid style={{ paddingBottom: "30px" }}></Grid>
      </Container>
    </Grid>
  );
}