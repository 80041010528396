import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./../index.css";
import { Box, Container, Grid } from "@material-ui/core";
import Image from "../assets/images/chatpate.jpg";
import Image3 from "../assets/images/momo.jpg";
import Image2 from "../assets/images/tandoori.jpg";
import Pak from "../assets/images/pak.jpg";
import Door from "../assets/dd.jpg";
import Eat from "../assets/es.jpg";
import Uber from "../assets/ua.jpg";
import Grub from "../assets/gh.jpg";
import Clover from "../assets/clover.png";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import ModalImage from "react-modal-image";
import Logob from "../assets/logo-b.jpg";
import ScrollMenu from "./ScrollMenu";

const useStyles = makeStyles((theme) => ({
  nplogo: {
    width: "400px",
    height: "auto",

    [theme.breakpoints.down("md")]: {
      width: "300px",
    },
  },
  nplogogrid: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0px!important",
      paddingTop: "0px!important",
    },
  },
  title: {
    fontSize: "55px",
    fontFamily: "Great Vibes",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
      textAlign: "center",
    },
  },
  subtitle: {
    fontSize: "22px",
    fontFamily: "Josefin Sans",
    textAlign: "center",
    fontWeight: "600",
    color: "black",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      textAlign: "center",
      left: 0,
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
      top: "60%",
    },
  },
  button: {
    fontFamily: "Poppins",
    justifyContent: "center",
    backgroundColor: "#165940",
    boxShadow: "rgb(0 0 0 / 20%) 0px 4px 10px 1px",
    alignItems: "center",
    color: "black",
    border: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
    "&:hover": {
      background: "#cb5b09",
      textColor: "black",
      border: "none",
    },
  },
  toplink: {
    fontSize: "16px",
    textDecoration: "none",
    fontFamily: "Poppins, sans-serif",
    color: "white",
    margin: "auto",
    padding: "4px",
    fontWeight: "600",
  },
  orderwith: {
    width: "120px",
    height: "auto",
    "&:hover": {
      transform: "scale(1.1)",
      transitionDuration: "0.5s",  
    },
  },
  food: {
    width: "300px",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "280px",
      "&:hover": {
        opacity: "0.9",
        transform: "scale(0.6)",
        transitionDuration: "0.5s",
      },
    },
  },
  caption:{

    fontFamily: "Josefin Sans",
    textAlign: "center",
    fontSize: "20px",
    color: "#cb5b09",
               
  }
}));

export default function About() {
  const classes = useStyles();
  return (
    <div
      id="about"
      className={classes.bg}
      style={{
        overflowX: "hidden",
      }}
    >
      <ScrollMenu />
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="center"
        style={{
          marginBottom: "30px",
          paddingTop: "50px",
          backgroundColor: "black",
          color: "antiquewhite",
        }}
      >
        <Grid
          item
          xs={12}
          md={2}
          style={{ textAlign: "center", marginBottom: "5px" }}
        >
          <Button
            variant="outlined"
            size="large"
            color="primary"
            className={classes.button}
          >
            <a href="tel:+15109229014" className={classes.toplink}>
              {" "}
              Call Now{" "}
            </a>
            <br />
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          style={{ textAlign: "center", marginBottom: "5px" }}
        >
          <Button
            variant="outlined"
            size="large"
            color="primary"
            className={classes.button}
          >
            <Link to="/menu" className={classes.toplink}>
              View Menu{" "}
            </Link>
            <br />
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          style={{ textAlign: "center", marginBottom: "5px" }}
        >
          <Button
            variant="outlined"
            size="large"
            color="primary"
            className={classes.button}
            style={{}}
          >
            <a
              href="https://www.clover.com/online-ordering/namastey-patio-oakland"
              className={classes.toplink}
              target="_blank"
              rel="noreferrer"
            >
              ORDER ONLINE
            </a>
            <br />
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          style={{ textAlign: "center", marginBottom: "5px" }}
        >
          <Button
            variant="outlined"
            size="large"
            color="primary"
            className={classes.button}
            style={{}}
          >
            <a
              href="https://www.opentable.com/r/namastey-oakland?corrid=7493e692-50ca-41f5-aa65-b2759df2dceb&avt=eyJ2IjoyLCJtIjowLCJwIjowLCJzIjowLCJuIjowfQ&p=2&sd=2022-01-03T19%3A00%3A00"
              className={classes.toplink}
              target="_blank"
              rel="noreferrer"
            >
              BOOK A TABLE
            </a>
            <br />
          </Button>
        </Grid>
        <Grid item xs={12} md={2} style={{ textAlign: "center" }}>
          <Button
            variant="outlined"
            size="large"
            color="primary"
            className={classes.button}
            style={{ width: "240px", marginBottom: "5px" }}
          >
            <a
              href="https://www.ezcater.com/catering/namastey-patio-3?fcv=1"
              className={classes.toplink}
              target="_blank"
              rel="noreferrer"
            >
              Order Catering Now
            </a>
            <br />
          </Button>
        </Grid>
      </Grid>

      {/* <Typography className={classes.title}> About Us</Typography> */}
      <div
        style={{
          backgroundColor: "black",
          color: "antiquewhite",
          paddingBottom: "20px",
        }}
      >
        <Container
          maxWidth="lg"
          component="main"
          style={{ marginBottom: "40px" }}
        >
          <Grid container spacing={8}>
            <Grid
              item
              xs={12}
              md={4}
              className={classes.nplogogrid}
              justify="center"
              alignItems="center"
            >
              <img src={Logob} alt="" className={classes.nplogo} />
            </Grid>
            <Grid item xs={12} sm={8} style={{ marginTop: "20px" }}>
              <Typography
                style={{
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "center",
                  fontSize: "20px",
                  marginBottom: "30px",
                }}
              >
                Namastey Patio Nepalese and Indian Cuisine is the best place to
                get authentic Nepalese and Indian food in Oakland, CA. Our
                professional staff provides a friendly and prompt service for a
                pleasant dining experience. We value our customers and strive to
                make your meal satisfying and fun. Our goal is to make sure that
                you have a memorable dining experience with your family and
                friends.
                <br />
                <br />
                <ul
                  style={{
                    fontSize: "24px",
                    fontWeight: "600",
                    fontFamily: "Josefin Sans",
                    color: "#165940",
                  }}
                >
                  <li>
                    We specialize in Nepali and Northern Indian Food with
                    Vegetarian and Vegan options.
                  </li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth="lg" component="main">
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={12} md={3} style={{ textAlign: "center" }}>
              <ModalImage
                small={Image}
                large={Image}
                hideDownload={true}
                hideZoom={true}
                className={classes.food}
                alt="Avocado Bhel-Puri Salad"
              />
              <Typography className={classes.caption}>
                {" "}
                Avocado Bhel-Puri Salad
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} style={{ textAlign: "center" }}>
              <ModalImage
                small={Image3}
                large={Image3}
                hideDownload={true}
                hideZoom={true}
                className={classes.food}
                alt="Chicken Momo"
              />
              <Typography className={classes.caption}> Chicken Momo</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} style={{ textAlign: "center" }}>
              <ModalImage
                small={Pak}
                large={Pak}
                hideDownload={true}
                hideZoom={true}
                className={classes.food}
                alt="Veggie Pakora"
              />
              <Typography className={classes.caption}>
                {" "}
                Veggie Pakora
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} style={{ textAlign: "center" }}>
              <ModalImage
                small={Image2}
                large={Image2}
                hideDownload={true}
                hideZoom={true}
                className={classes.food}
                alt="Chicken Tandoori"
              />
              <Typography className={classes.caption}>
                {" "}
                Chicken Tandoori
              </Typography>
            </Grid>
          </Grid>

          <Typography
            style={{
              fontFamily: "Poppins, sans-serif",
              textAlign: "center",
              fontSize: "20px",
              marginBottom: "30px",
              marginTop: "25px",
            }}
          >
            We offer a wide range of different entrees that you can find in
            Nepalese and Indian restaurants. We strive to provide delicious
            dishes as we never compromise on authentic flavor and rich taste.
            Our quality is evident in our ingredients' freshness and the
            hard-working culinary efforts of our kitchen. We would love nothing
            more than serve you, so visit us at Namastey Patio Nepalese and
            Indian Cuisine today! <br></br>
            <br></br>For Catering, please visit{" "}
            <a
              href="https://www.ezcater.com/catering/namastey-patio-3?fcv=1"
              style={{ color: "white", textdecoration: "none" }}
            >
              ez cater link.
            </a>
          </Typography>
        </Container>
      </div>
      <Container
        maxWidth="md"
        component="main"
        style={{
          backgroundColor: "#f0ba92",
          backgroundImage:
            "linear-gradient(to right, #cb5b09, #edc3a5 , #165940)",
          boxShadow: "rgb(0 0 0 / 50%) 0px 4px 10px 1px",
          marginBottom: "40px",
          marginTop: "40px",
          paddingBottom: "20px",
          paddingTop: "20px",
        }}
      >
        <p style={{ textAlign: "center", fontSize: "22px" }}>
          <p className={classes.subtitle} style={{ fontSize: "24px" }}>
            BUSINESS HOURS
          </p>
        </p>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography
              style={{
                fontFamily: "Josefin Sans",
                textAlign: "center",
                fontSize: "28px",
                color: "#165940",
              }}
            >
              {" "}
              <strong>
                {" "}
                <u>EVERYDAY</u>
              </strong>
            </Typography>{" "}
            <br />
            <Typography className={classes.subtitle}>
              {" "}
              11 : 30 AM - 3 PM | 4 : 30 PM - 9 PM{" "}
            </Typography>
            {/* <strong> MONDAY:</strong>   11:30AM - 3PM	|	4:30PM - 9PM<br/>           
              <strong>TUESDAY:</strong> 11:30AM - 3PM	|	4:30PM - 9PM<br/>            
              <strong> WEDNESDAY:</strong>  11:30AM - 3PM	|	4:30PM - 9PM
               <br/> <strong>THURSDAY:</strong> 11:30AM - 3PM	|	4:30PM - 9PM
               <br/><strong>FRIDAY:</strong> 111:30AM - 3PM	|	4:30PM - 9PM
               <br/><strong>SATURDAY:</strong>  11:30AM - 3PM	|	4:30PM - 9PM
               <br/><strong>SUNDAY:</strong> 	11:30AM - 3PM	|	4:30PM - 9PM */}
            <br />
          </Grid>
        </Grid>
      </Container>

      <Grid
        container
        spacing={0}
        className="map-responsive"
        id="map"
        alignItems="center"
        justifyContent="center"
        style={{
          marginBottom: "40px",
          marginTop: "40px",
        }}
      >
        <br />
        <iframe
          title="Google Map"
          src="https://www.google.com.qa/maps/d/embed?mid=1ZC4_-hV_jwOg02A-IkiWjkD_Ie6b1d8U&hl=en"
          width="1620"
          height="480"
        ></iframe>
      </Grid>
      <Container
        maxWidth="md"
        component="main"
        style={{ backgroundColor: "white" }}
      >
        <p className={classes.title} style={{ marginBottom: "0" }}>
          Order with
        </p>
        <p className={classes.subtitle}>[ Preferred ]</p>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justifyContent="center"
          style={{ marginBottom: "30px" }}
        >
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <a
              href="https://www.clover.com/online-ordering/namastey-patio-oakland"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={Clover}
                className={classes.orderwith}
                alt=""
                style={{ width: "130px" }}
              />
            </a>
          </Grid>
        </Grid>

        <p className={classes.subtitle}>or use 3rd party services: </p>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justifyContent="center"
          style={{ marginBottom: "80px" }}
        >
          <Grid item xs={6} sm={6} md={3} style={{ textAlign: "center" }}>
            <a href="https://www.doordash.com/store/namastey-patio-oakland-1451230/">
              <img src={Door} className={classes.orderwith} alt="" />
            </a>
          </Grid>
          <Grid item xs={6} sm={6} md={3} style={{ textAlign: "center" }}>
            <a
              href="https://www.ubereats.com/store/namastey-patio-nepalese-and-indian-cuisine/xoVckrQcSDulIBPuiHd4Vg/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Uber} className={classes.orderwith} alt="" />
            </a>
          </Grid>
          <Grid item xs={6} sm={6} md={3} style={{ textAlign: "center" }}>
            <a
              href="https://www.grubhub.com/restaurant/namastey-patio-5500-college-ave-oakland/2519881"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Grub} className={classes.orderwith} alt="" />
            </a>
          </Grid>
          <Grid item xs={6} sm={6} md={3} style={{ textAlign: "center" }}>
            <a
              href="https://eatstreet.com/oakland-ca/restaurants/namastey-patio-nepalese-and-indian-cuisine-college-ave"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Eat} className={classes.orderwith} alt="" />
            </a>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
