import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import {Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Topnav from '../components/Topnav'
import Scroll from './Scroll'


const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  tree: {
    padding: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  list: {
    width: 200,
  },
  sideBarIcon: {
    color: "white",
    cursor: "pointer",
  },
  link: {
    fontSize: "24px",
    textDecoration: "none",
    color: "white",
    display: "flex",
    fontFamily: "Josefin Sans",
    marginBottom: "10px",
    justifyContent: "space-evenly",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .5s ease-in-out",
      color: "#cb5b09",
    },
  },
  reslink: {
    textDecoration: "none",
    color: "#213C54",
  },
  reslink2: {
    textDecoration: "none",
    color: "#213C54",
    fontSize: "15px",
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}


ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,  
  window: PropTypes.func,
};

function ElevateAppBar(props) {
  const classes = useStyles();
 

  return (
    <React.Fragment>
      <Topnav />
      <ElevationScroll {...props}>
        <AppBar
          className={classes.root}
          position="sticky"
          style={{ backgroundColor: "#165940", color: "white", height: "55px" }}
        >
          <Toolbar>
            <Typography component={"span"} align="center">
              {/* <Scroll/>        */}
            </Typography>
            <Typography
              component={"span"}
              color="inherit"
              style={{ flexGrow: 1 }}
            >
              <Link to="/menu" className={classes.link}>
                Menu
              </Link>
            </Typography>
            <Typography
              component={"span"}
              color="inherit"
              style={{ flexGrow: 1 }}
            >
              <Link to="/gallary" className={classes.link}>
                Gallery
              </Link>
            </Typography>
            <Typography
              component={"span"}
              color="inherit"
              style={{ flexGrow: 1 }}
            >
              <Link to="/reviews" className={classes.link}>
                Reviews
              </Link>
            </Typography>
            <Typography
              component={"span"}
              color="inherit"
              style={{ flexGrow: 1 }}
            >
              <a
                href="https://www.clover.com/online-ordering/namastey-patio-oakland"
                className={classes.link}
                target="_blank"
                rel="noreferrer"
              >
                Order Online
              </a>
            </Typography>
            <Typography
              component={"span"}
              color="inherit"
              style={{ flexGrow: 1 }}
            >
              <a
                href="https://www.opentable.com/r/namastey-oakland?corrid=7493e692-50ca-41f5-aa65-b2759df2dceb&avt=eyJ2IjoyLCJtIjowLCJwIjowLCJzIjowLCJuIjowfQ&p=2&sd=2022-01-03T19%3A00%3A00"
                className={classes.link}
                target="_blank"
                rel="noreferrer"
              >
                Book a Table
              </a>
            </Typography>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </React.Fragment>
  );
};


export default ElevateAppBar;