import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Image from "../assets/images/salmon.jpeg";
import Image3 from "../assets/images/chicken.jpg";
import Image2 from "../assets/images/okra.jpg";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Bir from "../assets/images/biryani.jpg";
import Chat from "../assets/images/chatpate.jpg";
import Pan from "../assets/images/paneer.jpg";

import Kor from "../assets/images/korma.jpg";
import Lam from "../assets/images/lamb.jpg";
import Mom from "../assets/images/momo.jpg";
import Naa from "../assets/images/naan.jpg";
import Pak from "../assets/images/pakora.jpg";
import Pal from "../assets/images/palak.jpg";
import Tan from "../assets/images/tandoori.jpg";
import Pakv from "../assets/images/pak.jpg";
import Jac from "../assets/images/jack.jpg";
import Mal from "../assets/images/malai.jpg";
import Veg from "../assets/images/vegmomo.jpg";
import Mix from "../assets/images/mixveg.jpg";

import ModalImage from "react-modal-image";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "76vh",
  },
  food: {
    width: "90%",
    height: "auto",
    textAlign: "center",
    padding: "20px",
  },
  foodwrap: {
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
  },
}));
export default function Gallary() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h1
        style={{
          textAlign: "center",
          fontFamily: "Great Vibes",
          fontSize: "40px",
        }}
      >
        Gallary
      </h1>
      <Container
        maxWidth="lg"
        style={{ textAlign: "center", marginBottom: "50px" }}
      >
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
            <ModalImage
              small={Bir}
              large={Bir}
              hideDownload={true}
              hideZoom={true}
              className={classes.food}
              alt="Chicken Biryani"
            />
            <Typography
              style={{
                fontFamily: "Josefin Sans",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Chicken Biryani
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
            <ModalImage
              small={Pak}
              large={Pak}
              hideDownload={true}
              hideZoom={true}
              className={classes.food}
              alt="Chicken Choila"
            />
            <Typography
              style={{
                fontFamily: "Josefin Sans",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Chicken Choila
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
            <ModalImage
              small={Lam}
              large={Lam}
              hideDownload={true}
              hideZoom={true}
              className={classes.food}
              alt="Lamb Shank"
            />
            <Typography
              style={{
                fontFamily: "Josefin Sans",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Lamb Shank
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
            <ModalImage
              small={Jac}
              large={Jac}
              hideDownload={true}
              hideZoom={true}
              className={classes.food}
              alt=" Jackfruit Curry"
            />
            <Typography
              style={{
                fontFamily: "Josefin Sans",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Jackfruit Curry
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
            <ModalImage
              small={Mix}
              large={Mix}
              hideDownload={true}
              hideZoom={true}
              className={classes.food}
              alt="Mix Veggies"
            />
            <Typography
              style={{
                fontFamily: "Josefin Sans",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Mix Veggies
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
            <ModalImage
              small={Pal}
              large={Pal}
              hideDownload={true}
              hideZoom={true}
              className={classes.food}
              alt="Palak Paneer (Spinach & Cheese)"
            />
            <Typography
              style={{
                fontFamily: "Josefin Sans",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Palak Paneer (Spinach & Cheese)
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
            <ModalImage
              small={Mal}
              large={Mal}
              hideDownload={true}
              hideZoom={true}
              className={classes.food}
              alt=" Malai Kofta"
            />
            <Typography
              style={{
                fontFamily: "Josefin Sans",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Malai Kofta
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
            <ModalImage
              small={Veg}
              large={Veg}
              hideDownload={true}
              hideZoom={true}
              className={classes.food}
              alt="Veggie Momo"
            />
            <Typography
              style={{
                fontFamily: "Josefin Sans",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Veggie Momo
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
            <ModalImage
              small={Tan}
              large={Tan}
              hideDownload={true}
              hideZoom={true}
              className={classes.food}
              alt="Chicken Tandoori"
            />
            <Typography
              style={{
                fontFamily: "Josefin Sans",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Chicken Tandoori
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
            <ModalImage
              small={Mom}
              large={Mom}
              hideDownload={true}
              hideZoom={true}
              className={classes.food}
              alt="Chicken Momo"
            />
            <Typography
              style={{
                fontFamily: "Josefin Sans",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Chicken Momo
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
            <ModalImage
              small={Image3}
              large={Image3}
              hideDownload={true}
              hideZoom={true}
              className={classes.food}
              alt="Chicken Chilli"
            />
            <Typography
              style={{
                fontFamily: "Josefin Sans",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Chicken Chilli
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
            <ModalImage
              small={Pan}
              large={Pan}
              hideDownload={true}
              hideZoom={true}
              className={classes.food}
              alt=" Paneer Chilli"
            />
            <Typography
              style={{
                fontFamily: "Josefin Sans",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Paneer Chilli
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
            <ModalImage
              small={Chat}
              large={Chat}
              hideDownload={true}
              hideZoom={true}
              className={classes.food}
              alt="Avocado Bhel-Puri Salad"
            />
            <Typography
              style={{
                fontFamily: "Josefin Sans",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Avocado Bhel-Puri Salad
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
            <ModalImage
              small={Image2}
              large={Image2}
              hideDownload={true}
              hideZoom={true}
              className={classes.food}
              alt="Bhindi Bhaji (Okra)"
            />
            <Typography
              style={{
                fontFamily: "Josefin Sans",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Bhindi Bhaji (Okra)
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
            <ModalImage
              small={Kor}
              large={Kor}
              hideDownload={true}
              hideZoom={true}
              className={classes.food}
              alt="Chicken Korma"
            />
            <Typography
              style={{
                fontFamily: "Josefin Sans",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Chicken Korma
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
            <ModalImage
              small={Naa}
              large={Naa}
              hideDownload={true}
              hideZoom={true}
              className={classes.food}
              alt="Garlic Naan"
            />
            <Typography
              style={{
                fontFamily: "Josefin Sans",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Garlic Naan
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
            <ModalImage
              small={Image}
              large={Image}
              hideDownload={true}
              hideZoom={true}
              className={classes.food}
              alt="Salmon with House Salad"
            />
            <Typography
              style={{
                fontFamily: "Josefin Sans",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Salmon with House Salad
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
            <ModalImage
              small={Pakv}
              large={Pakv}
              hideDownload={true}
              hideZoom={true}
              className={classes.food}
              alt="Veggie Pakora"
            />
            <Typography
              style={{
                fontFamily: "Josefin Sans",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Veggie Pakora
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
