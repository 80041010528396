import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { Grid } from "@material-ui/core";

import Header from "../assets/images/header.jpg";
import Headerb from "../assets/images/headerb.jpg";
import Headerc from "../assets/images/headerc.jpg";
import Headerd from "../assets/images/headerd.jpg";
import Headere from "../assets/images/headere.jpg";
import Headerf from "../assets/images/headerf.jpg";
import Headerg from "../assets/images/headerg.jpg";
import Headerh from "../assets/images/headerh.jpg";
import Holiday from "../assets/images/holiday-banner.jpg";

const useStyles = makeStyles((theme) => ({
  legend: {
    fontSize: "22px",
    color: "#fff",
    backgroundColor: "#cb5b09",
    boxShadow: "rgb(0 0 0 / 50%) 1px 8px 10px 1px",
    padding: "10px",
    position: "absolute",
    zIndex: "999",
    margin: "0 auto",
    left: 0,
    right: "-68%",
    top: "83%",
    textAlign: "center",
    width: "18%",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .5s ease-in-out",
    },
    [theme.breakpoints.down("md")]: {
      width: "58%",
      fontSize: "15px",
      padding: "5px",
      top: "70%",
      left: "-68%",
    },
  },

  legend2: {
    fontSize: "22px",
    color: "#fff",
    backgroundColor: "#cb5b09",
    boxShadow: "rgb(0 0 0 / 50%) 1px 8px 10px 1px",
    padding: "10px",
    position: "absolute",
    zIndex: "999",
    margin: "0 auto",
    left: 0,
    right: "-68%",
    top: "73%",
    textAlign: "center",
    width: "20%",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .5s ease-in-out",
    },
    [theme.breakpoints.down("md")]: {
      width: "65%",
      backgroundColor: "transparent",
      fontSize: "12px",
      padding: "5px",
      top: "62%",
      left: "-68%",
    },
  },

  legend3: {
    fontSize: "14px",
    color: "#fff",
    backgroundColor: "#cb5b09",
    boxShadow: "rgb(0 0 0 / 50%) 1px 8px 10px 1px",
    padding: "10px",
    position: "absolute",
    zIndex: "999",
    margin: "0 auto",
    left: 0,
    right: "-68%",
    top: "83%",
    textAlign: "center",
    width: "15%",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .5s ease-in-out",
    },
    [theme.breakpoints.down("md")]: {
      width: "58%",
      fontSize: "7px",
      padding: "5px",
      top: "75%",
      left: "-68%",
      backgroundColor: "transparent",
    },
  },
  link1: {
    fontFamily: "Poppins",
    fontSize: "20px",
    textDecoration: "none",
    color: "black",
    padding: "0px 10px",
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .3s ease",
      color: "white",
    },
  },
  link2: {
    fontFamily: "Poppins",
    fontSize: "20px",
    textDecoration: "none",
    color: "white",
    padding: "0px 10px",
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .3s ease",
      color: "black",
    },
  },
  pStyle: {
    width: "44%",
    height: "auto",
    position: "absolute",
    top: "30%",
    left: "5%",
    justifyContent: "center",
    alignItems: "center",
    color: "#575656",
    backgroundColor: "fff",
    textShadow: "#e4e4e4 4px 4px 7px;",
    [theme.breakpoints.down("sm")]: {
      fontSize: "75px",
      textAlign: "center",
    },
  },
  subpStyle: {
    fontSize: "30px",
    fontFamily: "Courgette",
    position: "absolute",
    top: "50%",
    left: "5%",
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
    color: "black",
    textShadow: "#e4e4e4 7px 7px 7px;",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
      textAlign: "center",
      textShadow: "2px 2px 3em #fff, 0 0 0.4em #fff;",
      left: 0,
    },
  },
  button: {
    fontSize: "20px",
    fontFamily: "Poppins",
    position: "absolute",
    justifyContent: "center",
    backgroundColor: "#e0d12e",
    alignItems: "center",
    color: "black",
    border: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .3s ease",
      backgroundColor: "black",
      border: "none",
    },
  },
  button2: {
    fontSize: "20px",
    fontFamily: "Poppins",
    position: "absolute",
    backgroundColor: "black",
    top: "78%",
    left: "6%",
    justifyContent: "center",
    alignItems: "center",
    color: "white",

    [theme.breakpoints.down("lg")]: {
      fontSize: "25px",
      top: "70%",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "25px",
      top: "68%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .3s ease",
      backgroundColor: "#e0d12e",
      border: "none",
    },
  },
  padding: {
    marginTop: "0px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "14%",
    },
  },
}));

const arrowStyles = {
  position: "absolute",
  zIndex: 2,
  // top: 'calc(50% - 15px)',
  top: "5%",
  width: "3%",
  height: "100%",
  color: "#000",
  cursor: "pointer",
  "&hover": {
    top: 0,
    bottom: 0,
    backgroundColor: "#cb5b09",
    opacity: ".4",
  },
};
export default function Mainimg() {
  const classes = useStyles();
  return (
    <div className={classes.padding}>
      <Carousel
        autoPlay
        transitionTime={1500}
        interval={4000}
        infiniteLoop
        showThumbs={false}
        showStatus={true}
      >
        {/* <Grid>
          <img src={Holiday} alt="" />
          <p className={classes.legend2}>10% OFF in Dine in and Take Out</p>{" "}
          <p className={classes.legend3}>Minimum required purchase: $20</p>
        </Grid> */}
        <Grid>
          <img src={Header} alt="" />
        </Grid>
        <Grid>
          <img src={Headerb} alt="" />
          <p className={classes.legend}>Avocado Bhel-Puri Salad</p>
        </Grid>
        <Grid>
          <img src={Headerc} alt="" />
          <p className={classes.legend}>Chicken Tandoori</p>
        </Grid>
        <Grid>
          <img src={Headerd} alt="" />
          <p className={classes.legend}>Lamb Shank</p>
        </Grid>
        <Grid>
          <img src={Headere} alt="" />
          <p className={classes.legend}>Dahi Puri Chaat</p>
        </Grid>
        <Grid>
          <img src={Headerf} alt="" />
          <p className={classes.legend}>Palak Paneer</p>
        </Grid>
        <Grid>
          <img src={Headerg} alt="" />
          <p className={classes.legend}> Chicken & Veggie Momo</p>
        </Grid>
        <Grid>
          <img src={Headerh} alt="" />
          <p className={classes.legend}>Chicken Choila</p>
        </Grid>
      </Carousel>
    </div>
  );
}
