import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom'
import { AppBar, Toolbar, Grid, Box, } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../assets/logo.png'

const useStyles = makeStyles({
  list: {
    width: 230,
  },
  fullList: {
    width: "auto",
  },
  color: {
    backgroundColor: "#165940",
    color: "grey",
  },
  reslogo: {
    height: "30px",
  },
  reslink: {
    textDecoration: "none",
    fontSize: "20px",
    fontFamily: "Josefin Sans",
    color: "#cb5b09",
    padding: "5px",
  },
});

export default function TemporaryDrawer() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
          <ListItem key={3} button divider>
            <Link to="/" className={classes.reslink}>
              Home
            </Link>
          </ListItem>
          <ListItem key={1} button divider>
            <Link to="/about" className={classes.reslink}>
              About
            </Link>
          </ListItem>
          <ListItem key={2} button divider>
            <Link to="/menu" className={classes.reslink}>
              Menu{" "}
            </Link>
          </ListItem>
          <ListItem key={4} button divider>
            <Link to="/gallary" className={classes.reslink}>
              Gallery
            </Link>
          </ListItem>
          <ListItem key={5} button divider>
            <Link to="/reviews" className={classes.reslink}>
              Reviews
            </Link>
          </ListItem>
          <ListItem key={6} button divider>
            <a
              href="https://www.clover.com/online-ordering/namastey-patio-oakland"
              className={classes.reslink}
              target="_blank"
              rel="noreferrer"
            >
              Order Online
            </a>
          </ListItem>
          <ListItem key={6} button divider>
            <a
              href="https://www.opentable.com/r/namastey-oakland?corrid=7493e692-50ca-41f5-aa65-b2759df2dceb&avt=eyJ2IjoyLCJtIjowLCJwIjowLCJzIjowLCJuIjowfQ&p=2&sd=2022-01-03T19%3A00%3A00"
              className={classes.reslink}
              target="_blank"
              rel="noreferrer"
            >
              Book a Table
            </a>
          </ListItem>
          <ListItem key={7} button divider>
            <a href="tel:+15109229014" className={classes.reslink}>
              Call Now{" "}
            </a>
          </ListItem>
        </List>
      </div>
    );

    return (
      <div>
        <Box display={{ xs: "block", md: "none", sm: "block", lg: "none" }}>
          {["left"].map((anchor) => (
            <React.Fragment key={anchor}>
              <AppBar className={classes.color}>
                <Toolbar>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <MenuIcon
                      style={{
                        fontSize: "30px",
                        marginTop: "6px",
                        color: "#fff",
                      }}
                      onClick={toggleDrawer(anchor, true)}
                    >
                      {anchor}/>
                    </MenuIcon>
                    <Drawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                    >
                      {list(anchor)}
                    </Drawer>
                    <Link to="/">
                      <img
                        src={logo}
                        className={classes.reslogo}
                        alt=""
                        style={{ paddingTop: "5px" }}
                      />
                    </Link>
                  </Grid>
                </Toolbar>
              </AppBar>
            </React.Fragment>
          ))}
        </Box>
      </div>
    );
}